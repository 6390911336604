import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Stack, ThemeProvider } from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import { useOpenShiftContext } from "@src/app/openShifts/context";
import { Button } from "@src/appV2/redesign/components/Button";
import { Illustration } from "@src/appV2/redesign/components/Illustration";
import { getRedesignTheme } from "@src/appV2/redesign/theming";

export function EmptyShiftBlock() {
  const { setShiftBookingType } = useOpenShiftContext();
  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <Stack spacing={7} alignItems="center" justifyContent="center" flex={1}>
        <Illustration type="block-shift" />
        <Text variant="h4">No blocks at this moment</Text>
        <ExternalLink to="https://support.clipboardhealth.com/hc/en-us/articles/21777980781207">
          What is a block of shifts?
        </ExternalLink>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setShiftBookingType("per-diem");
          }}
        >
          Browse Per Diem Shifts
        </Button>
      </Stack>
      <Stack flex={1} alignItems="center" justifyContent="end" sx={{ pb: 4 }}>
        <Text variant="body1" align="center">
          Want repeat shifts at your favorite workplaces?
        </Text>
        <Text variant="body1" align="center">
          If a nearby workplace wants to activate block shifts, email us their name and information
          at{" "}
          <ExternalLink
            to="mailto:productfeedback@clipboardhealth.com"
            sx={{
              color: (theme) => theme.palette.info.main,
            }}
          >
            productfeedback@clipboardhealth.com
          </ExternalLink>
        </Text>
      </Stack>
    </ThemeProvider>
  );
}
